import React from 'react';
import Layout from '../components/Layout';
import { motion } from "framer-motion";
import { useSpring, animated } from 'react-spring';
import { Link } from 'gatsby'


const Home = ({location}) => {
  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: -30},
  }
  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
  const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`
  const trans2 = (x, y) => `translate3d(${x / 8 + 35}px,${y / 8 - 230}px,0)`
  const trans3 = (x, y) => `translate3d(${x / 6 - 250}px,${y / 6 - 200}px,0)`
  const trans4 = (x, y) => `translate3d(${x / 80}px,${y / 25}px,0)`
  const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }))

  return (
    <Layout>
      <style>
        {'main,html { background-color: #FFDEC4; color: #3C1E1E; }'}
        {'.line { background-color: #F05E59; }'}
        {'a { color: #3C1E1E; }'}
        {'a:hover { color: #FFDEC4; }'}
        {'a, a:link { background-image: linear-gradient( #FFDEC4 50%, #3C1E1E 50%); }'}
        {'.cursor {background-color: #3C1E1E}'}
        {'.cursor--link-hovered {background-color: #FFDEC4}'}
      </style>

      <div class="background" onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
          <motion.div
            animate={{ rotate: [5, 15, 30, 15, 5] }}
            transition={{ duration: 20, repeat: Infinity }}>
            <animated.div class="line" id="line1" style={{ transform: props.xy.interpolate(trans1) }}/>
          </motion.div>
          <motion.div
            animate={{ rotate: [-20, -10, -20, -10, -20] }}
            transition={{ duration: 50, repeat: Infinity }}>
            <animated.div class="line" id="line2" style={{ transform: props.xy.interpolate(trans2) }}/>
          </motion.div>
          <motion.div
              animate={{ rotate: [40, 30, 20, 30, 40] }}
              transition={{ duration: 80, repeat: Infinity }}>
              <animated.div class="line" id="line3" style={{ transform: props.xy.interpolate(trans3) }} />
          </motion.div>
      </div>
          

      <div class='content' onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
        <animated.div class="typewriter3" style={{ transform: props.xy.interpolate(trans4) }}>
          <h1>h<b>e</b>llo<b>.</b></h1>
        </animated.div>

        <animated.div style={{ transform: props.xy.interpolate(trans4) }}>
          <motion.div
          initial="hidden"
          animate="visible"
          className="helloContent"
          transition={{
            delay: 1, 
            type: "spring", stiffness: 100,
            default: { duration: 1 },
            damping: 20,}}
          variants={variants}>
            <p>
              My name is <span>Keaten Sullivan</span>.
            </p>
            <h5>
              <span>"</span>I am a <b>Software Developer</b> with a passion for coding applications
              that are designed to be easy to use. My graphical
              background helps me create projects that prioritise the user experience.<span>"</span>
            </h5>
            <br/><br/>
            
            <p>
              <span style={{borderTop: "4px solid #F05E59", paddingTop: "10px"}}>2020 RMIT</span><br/>
              <b>Bachelor of Information Technology with Distinction<br/></b>
              Nominated for Vice-Chancellor Award by School of Science & Engineering.<br/>
              Invited to the Golden Key Honor Society.
            </p>
            <br/>
            <p>
            <span style={{borderTop: "4px solid #F05E59", paddingTop: "10px"}}>2012 QUT</span><br/>
              <b>Bachelor of Fine Arts</b>
            </p>
          
         </motion.div>
        </animated.div>

        <div style={{height: '300px', width: '200px'}}/>
    
      </div>

    </Layout>
  )
}

export default Home;